<template>
  <div>
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>种子企业</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <div class="goodsType">
        <!--        筛选条件-->
        <div class="goodsType_row">
          <el-row v-for="(item,index) in resultList" :key="index+1">
            <el-col :span="3" class="goodsType_rowLeft">{{item.name}}</el-col>
            <div class="goodsType_rowRight">
              <el-col :span="2" v-for="(itm,idx) in item.childNode" :key="idx+111">
              <span
                      :class="itm === replaceAddress || (idx === 0 && replaceAddress === '')?'isSelect':''"
                      @click="getSelectInfo(itm,item.type)"
              >{{itm}}</span>
              </el-col>
            </div>
          </el-row>
          <el-row v-for="(item,index) in kindList" :key="index">
            <el-col :span="3" class="goodsType_rowLeft">{{item.name}}</el-col>
            <div class="goodsType_rowRight">
              <el-col :span="2" v-for="(itm,idx) in item.childNode" :key="idx+1112">
              <span
                      :class="itm === replaceKind || (idx === 0 && replaceKind === '')?'isSelect':''"
                      @click="getSelectInfo(itm,item.type)"
              >{{itm}}</span>
              </el-col>
            </div>
          </el-row>
        </div>

      </div>
      <!--    内容-->
      <el-form :model="Params" :inline="true" class="demo-form-inline">
        <el-form-item label="搜索查询：">
          <el-input v-model="Params.seek" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch()">查询</el-button>
        </el-form-item>
      </el-form>
      <main>
        <div class="Item_Content" v-for="(Items,Indexs) in infoList" :key="Indexs+1">
          <div class="ItemLeft">
            <div class="content_title" @click="goDetail(Items.id)">
              <img src="../../../public/static/img/market/gs.icon.png" height="32" width="30"/>
              <p>{{Items.firmName}}</p>
            </div>
            <div class="DetailText">
              <span>{{Items.contactPhone}}</span>
              <span class="icon">|</span>
              <span>{{Items.firmUrl}}</span>
            </div>
            <div class="introduction">
              简介：{{Items.firmIntro}}
            </div>
          </div>
          <!--        查看详情-->
          <div class="showDetail" @click="goDetail(Items.id)">
            查看详情
          </div>

        </div>

      </main>
      <el-pagination
              background
              layout="prev, pager, next"
              :total="infolistTotal"
              :page-size="Params.pageSize"
              :current-page.sync="Params.pageNum"
              @current-change="paginationChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
  import '../../../src/pages/market/market.css';

  export default {
    data() {
      return {
        startTime: null,
        pageName: "种子企业(SeedEnterprise.vue)",
        resultList: [ // 谁选列表
          {
            name: '所在省',
            type: 1,
            childNode: ['全部', '河北省', '山西省', '辽宁省', '吉林省', '黑龙江省',
              '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省',
              '河南省', '湖北省', '湖南省', '广东省', '海南省', '四川省', '贵州省', '云南省', '陕西省', '甘肃省', '青海省', '台湾省', '北京市', '天津市', '上海市', '重庆市', '内蒙古', '广西', '西藏', '宁夏', '新疆', '香港', '澳门']
          }
        ],
        kindList: [ // 种子列表
          {
            name: '种子类型', type: 2, childNode: ['全部', '小麦', '谷子', '玉米', '水稻', '棉花', '花生', '黄豆', '绿豆', '向日葵', '高粱',
              '西瓜', '蚕豆', '菜豆', '番茄', '马铃薯', '苹果', '香蕉', '梨', '柑橘'
            ]
          }
        ],
        Params: {
          pageNum: 1,
          pageSize: 10,
          province: '', // 省份
          seedKind: '', // 种子种类
          seek: '', // 搜索
          type: '1' // 默认值,(进入页面没有任何筛选时传 1 )
        },
        infoList: [], // 数据列表
        infolistTotal: 0,
        replaceKind: '',
        replaceAddress: ''

      };
    },
    beforeCreate() {
      this.startTime = performance.now();
      let this_ = this;
      //1.页面停留时间
      window.addEventListener('beforeunload', function() {
        let endTime = performance.now();
        let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
        console.log("stayTime", stayTime);

        window.SZHN_FIRE_BEAR?.trackEvent({
          key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
          segmentation: {
            stayTime: stayTime,
          }
        })
      });
      //3.页面加载时间
      window.addEventListener('load', function() {
        let endTime = performance.now();
        let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
        console.log("loadTime", loadTime);
        window.SZHN_FIRE_BEAR?.trackEvent({
          key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
          segmentation: {
            loadTime: loadTime,
          }
        })
      });
    },
    created() {
      //2.用户浏览量
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          pageViews: 1,
        }
      })
      //4.网络请求状态
      this.trackNetworkRequest("https://nfggy.com");
    },
    mounted() {
      // this.marketSecondList();
      this.getInfoList(this.Params);
    },
    methods: {
      trackNetworkRequest(url) {
        let requestStartTime = performance.now();
        let this_ = this;

        fetch(url)
            .then(response => {
              let requestEndTime = performance.now();
              let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
              console.log("requestSuccessTime", requestTime);
              window.SZHN_FIRE_BEAR?.trackEvent({
                key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
                segmentation: {
                  requestTime: requestTime,
                  requestStatu: "success",
                  requestResult: response.toString()
                }
              })
            })
            .catch(error=>{
              let requestEndTime = performance.now();
              let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
              console.log("requestErrorTime", requestTime);

              window.SZHN_FIRE_BEAR?.trackEvent({
                key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
                segmentation: {
                  requestTime: requestTime,
                  requestStatu: "error",
                  requestResult: error.toString()
                }
              })
            })
      },
      // async marketSecondList() {
      //   const api = this.$fetchApi.supplementarySelect.api;
      //   const data = await this.$fetchData.fetchPost({}, api, 'json');
      //   if (data.code === '200' && data.result) {
      //
      //     this.resultList[0].childNode = data.result.province;
      //     this.resultList[1].childNode = data.result.seedKind;
      //     this.resultList = JSON.parse(JSON.stringify(this.resultList));
      //   }
      // },
      async getInfoList(params) {
        const api = this.$fetchApi.supplementaryList.api;
        const data = await this.$fetchData.fetchPost(params, api, 'json');
        if (data.code === '200' && data.result) {
          this.infoList = data.result.rows;
          if(data.result.total){
            this.infolistTotal = data.result.total;
          }else{
            this.infolistTotal = 0;
          }

        }else{
          this.infoList = []
          this.infolistTotal = 0;
        }
      },
      paginationChange(pageNum) { // 分页页码
        this.Params.pageNum = pageNum;
        this.getInfoList(this.Params);
      },
      getSelectInfo(name, type) { // 选择分类
        this.Params.type = '';
        if (type === 1) {
          this.replaceAddress = name;
          if (this.replaceAddress === '全部') {
            this.Params.province = '';
          } else {
            this.Params.province = this.replaceAddress;
          }

        } else {
          this.replaceKind = name;
          if (this.replaceKind === '全部') {
            this.Params.seedKind = '';
          } else {
            this.Params.seedKind = this.replaceKind;
          }
          // this.Params.seedKind = name;
        }
        this.getInfoList(this.Params);
        // console.log(this.Params, type);
      },
      //  搜索框
      onSearch() {
        this.Params.type = '';
        this.getInfoList(this.Params);
      },
      goDetail(id) {
        console.log(id);
        this.$router.push({
          name: 'SeedDetail',
          query: {
            id: id
          }
        });
      }
    }
  };
</script>

<style scoped lang="less">
  main {
    padding-left: 6px;
  }

  /*公司名称*/
  .content_title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 128, 254, 1);
    margin-bottom: 19px;
    cursor: pointer;

    img {
      padding-right: 7px;
    }
  }

  .DetailText {
    margin-bottom: 19px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(84, 84, 84, 1);
  }

  .introduction {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(84, 84, 84, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  /*列表单条*/
  .Item_Content {
    margin-bottom: 61px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ItemLeft {
      width: 936px;
    }
  }

  .icon {
    margin-left: 20px;
    margin-right: 20px;
  }

  /*  查看详情*/
  .showDetail {
    background-color: #1975ff;
    width: 76px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    border-radius: 6px;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }

  /*搜索框*/
  /deep/ .el-form-item__label {
    font-size: 20px;
  }

  /deep/ .el-button {
    box-sizing: border-box;
    font-size: 18px;
    width: 100px;
    height: 40px;
  }

  /deep/ .el-input__inner {
    width: 240px;
  }

  .goodsType {
    height: 210px !important;
  }

  .goodsType_rowRight {
    width: 80%;
    /*margin-right: 0;*/
    height: auto;

    span {
      padding: 0;
    }
  }

  .goodsType_row {
    line-height: 40px;
    overflow: hidden;

  }

  /deep/ .el-row {
    border-bottom: 1px dashed #d9d9d9;
    padding-bottom: 5px;
  }

  .isSelect {
    color: #1975ff;
  }
</style>
